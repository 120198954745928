<template>
  <item-list-view
    ref="list"
    :api="api"
    :headers="headers"
    id-key="id"
    title="Файлы"
    :allowed-search="true"
    :allowed-create="mode === modes.EDIT"
    :allowed-remove="mode === modes.EDIT"
    :allowed-select="mode === modes.SELECT"
    :update-route="mode === modes.EDIT ? 'File' : ''"
    @select="$emit('select', $event)"
    remove-title="Удаление файла"
    remove-message="Вы действительно хотите удалить файл?"
  >
    <template v-slot:before-table>
      <v-flex style="width: 100%">
        <files-uploader @success="fetchDebounce" />
      </v-flex>
    </template>
    <template v-slot:item.title="{ item }">
      <div class="pt-2 pb-2">
        <router-link
          :to="{ name: 'File', params: { id: item.id } }"
          class="text-subtitle-1 text-decoration-none"
        >
          {{ item.title || item.originalFileName }}
        </router-link>
        <div v-if="item.title" style="opacity: 0.7; line-height: 1.25em">
          {{ item.originalFileName }}
        </div>
        <div v-if="item.url" class="mt-2">
          <code
            class="whitespace-pre overflow-x-scroll pt-1 pb-1 pl-2 pr-2"
            style="cursor: pointer; background: #eee; color: #939393; font-weight: 400"
            v-clipboard:copy="item.url"
            v-clipboard:success="() => $notify.success({ title: 'Скопировано в буфер обмена' })"
          >
            {{ item.url }}
            <v-icon x-small>fa-copy</v-icon>
          </code>
        </div>

      </div>
    </template>

    <template v-slot:item.createdAt="{ item }">
      {{ item.createdAt | dateTime }}
    </template>
  </item-list-view>
</template>

<script>
import config from '@/config'
import api from '@/services/files'
import ItemListView from '@/components/ItemListView'
import debounce from 'lodash/debounce'
import FilesUploader from '@/components/elements/upload/FilesUploader.vue'

const modes = {
  EDIT: 'edit',
  SELECT: 'select'
}

export default {
  components: {
    ItemListView,
    FilesUploader
  },
  props: {
    mode: {
      default: modes.EDIT,
      validator: (prop) => Object.values(modes).includes(prop)
    }
  },
  data: () => ({
    api: api,
    modes,
    headers: [
      { text: 'Название', value: 'title' },
      { text: 'Загружен', value: 'createdAt' },
      { text: '', value: 'action' },
    ],
    options: {
      url: config.fileUploadUrl,
      maxFilesize: 15,
    }
  }),
  methods: {
    fetchDebounce: debounce(function() {
      this.$refs.list.searchDebounce()
    }, 500),
  }
}
</script>
