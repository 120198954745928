<template>
  <files-list />
</template>

<script>
import FilesList from '@/components/Files/FilesList.vue'

export default {
  components: {
    FilesList
  }
}
</script>
