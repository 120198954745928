import BaseService from './baseService'

class FileService extends BaseService {
  get url () {
    return 'files'
  }
}

export default new FileService()

